import React, {Component} from 'react';
import Banner   from "./banner/banner";
import {Col, Row} from "antd";
import News from "./news/news";
import {colorBgProduct, titleStyle} from "../../css/cssJs";
import Company from "./company/company";
import Product from "./product/product";


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div>
                <div style={{

                }}>
                    <Banner />
                </div>
                {/*<Row style={{marginBottom: '20px'}}>*/}
                {/*    <Col span={24}>*/}
                {/*        */}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*集团介绍模块*/}
                <Company/>
                {/*产品展示模块*/}
                <div
                    style={{
                        maxWidth: '1920px',
                        margin: '0 auto'
                    }}
                >
                    <Row style={{
                        background:colorBgProduct
                    }}>
                        <Col span={24}>
                            <h1 style={titleStyle}>
                                SERVICES
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Product/>
                        </Col>
                    </Row>
                </div>
                {/*新闻模块*/}
                <Row style={{
                    maxWidth: '1920px',
                    margin: '0 auto',
                }}>
                    <Col span={24}>
                        <News/>
                    </Col>
                </Row>

            </div>
        );
    }
}

export default Home;
